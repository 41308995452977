import deepEqual from 'deep-eql';
import { SkinType } from 'gabi-api-ts/v2/patient/common/patient_common';
import {Sex, Date as ApiDate} from 'gabi-api-ts/v2/patient/common/patient_common';
import {Patient} from 'gabi-api-ts/v2/patient/query/patient_query';
import React, {ChangeEvent, FormEvent} from 'react';
import autoBind from 'react-autobind';
import {WithTranslation, withTranslation} from 'react-i18next';
import Styled from 'styled-components';

import PatientSkinTypeTooltip from '@/components/business/analytics/patient/common/patient-skin-type-tooltip';
import {Button} from '@/components/buttons/button';
import {DateInput, DateInputValue} from '@/components/form/input/date-input';
import {PatientChildSexEnum} from '@/enum/patient-child-sex-enum';
import {medias} from '@/themes/darkmode';
import {apiDateToday, getDaysDifference, isBeforeOrEqual} from '@/util/apidate-util';

import '@/stylesheets/datepicker-darkmode-theme.css';

/* eslint-disable react/prop-types */

type PatientChildFormProps = {
    className?: string;
    patient?: Patient;
    hideCheckboxes?: boolean;
    loading: boolean;
    onChanged: (notSavedDataChild: boolean) => void;
    onSubmit: (patient: PatientChildFormSubmitData) => void;
}

type PatientChildFormState = {
    firstName?: string;
    lastName?: string;
    isBirthDateValid: boolean;
    birthDate: {
        day?: number | '';
        month?: number | '';
        year?: number | '';
    };
    sex: Sex | 0;
    skinType?: unknown;
    noMonitoringRequired: boolean;

    oldChild: boolean;
    neoChild: boolean;
    displayOldChild: boolean;
    displayNeoChild: boolean;
}
type PatientChildFormSubmitData = {
    id?: string;
    firstName?: string;
    lastName?: string;
    birthDate: {
        day?: number | '';
        month?: number | '';
        year?: number | '';
    };
    sex: Sex | 0;
    skinType?: unknown;
    noMonitoringRequired: boolean;
}

function isOldChild(birthDate: ApiDate, today: ApiDate) {
    const oldChildDate = {
        ...today,
        year: today.year-13,
    };
    return isBeforeOrEqual(birthDate, oldChildDate);
}

function isNeoChild(birthDate: ApiDate, today: ApiDate) {
    return getDaysDifference(birthDate, today) < 28;
}

class PatientChildForm extends React.Component {
    state: PatientChildFormState = {
        firstName: '',
        lastName: '',
        isBirthDateValid: false,
        birthDate: {
        },
        sex: 0,
        skinType: '',
        noMonitoringRequired: false,
        oldChild: false,
        neoChild: false,
        displayOldChild: false,
        displayNeoChild: false,
    };
    initialState: {[key:string]: unknown};
    // @ts-expect-error declared in the parent class
    props: PatientChildFormProps;

    constructor(props: PatientChildFormProps) {
        super(props);

        autoBind(this);

        if (props.patient) {
            const today = apiDateToday();
            const birthDate = props.patient.birthDate;

            this.state = {
                firstName: props.patient.firstName,
                lastName: props.patient.lastName,
                isBirthDateValid: true,
                birthDate: {
                    day: props.patient?.birthDate?.day ?? 1,
                    month: props.patient?.birthDate?.month ?? 1,
                    year: props.patient?.birthDate?.year ?? 2020,
                },
                sex: props.patient.sex,
                skinType: props.patient.skinType,
                noMonitoringRequired: true,
                oldChild: !birthDate ? false : isOldChild(birthDate, today),
                neoChild: !birthDate ? false : isNeoChild(birthDate, today),
                displayOldChild: !birthDate ? false : isOldChild(birthDate, today),
                displayNeoChild: !birthDate ? false : isNeoChild(birthDate, today),
            };
        }

        this.initialState = this.state;
    }

    isFilled() {
        const state = this.state;

        return (
            state.firstName && state.firstName !== '' &&
            state.lastName && state.lastName !== '' &&
            state.birthDate && state.birthDate.day !== '' && state.birthDate.month !== '' && state.birthDate.year !== '' &&
            state.isBirthDateValid &&
            state.sex && (!!state.sex) &&
            state.skinType && state.skinType !== '' &&
            state.noMonitoringRequired &&
            (!state.displayOldChild || state.displayOldChild && state.oldChild) &&
            (!state.displayNeoChild || state.displayNeoChild && state.neoChild)
        );
    }

    checkNotSavedDataChild() {
        if (this.props.onChanged) {
            const notSavedDataChild = !deepEqual(this.state, this.initialState);
            this.props.onChanged(notSavedDataChild);
        }
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props as unknown as WithTranslation;

        return (
            <div className={props.className}>
                <form onSubmit={this.handleSubmit}>
                    <p><em>{t(['Fields marked with an asterisk (*) are required.', 'global.mandatoryFields'])}</em></p>
                    <div className="multiFieldWrapper">
                        <div className="fieldWrapper">
                            <label htmlFor="patientChild-firstName" className={`mandatoryField ${(state.firstName === '') ? 'empty' : 'filled'}`}>{t(['First name', 'patient.firstName'])}</label>
                            <input name="firstName" maxLength={70} id="patientChild-firstName" required aria-required="true" autoFocus value={state.firstName} onChange={this.handleChangeFirstName} />
                        </div>
                        <div className="fieldWrapper">
                            <label htmlFor="patientChild-lastName" className={`mandatoryField ${(state.lastName === '') ? 'empty' : 'filled'}`}>{t(['Last name', 'patient.lastName'])}</label>
                            <input name="lastName" maxLength={70} id="patientChild-lastName" required aria-required="true" value={state.lastName} onChange={this.handleChangeLastName} />
                        </div>
                    </div>
                    <div className="multiFieldWrapper">
                        <div className="fieldWrapper">
                            <label htmlFor="patientChild-sex" className={`mandatoryField ${(state.sex === 0) ? 'empty' : 'filled'}`}>{t(['Sex', 'patient.sex'])}</label>
                            <select name="sex" id="patientChild-sex" required aria-required="true" value={state.sex} onChange={this.handleChangeSex}>
                                <option value={0}></option>
                                {Object.entries(PatientChildSexEnum).filter(sex => sex[1] !== 0).map((sex) => (
                                    <option key={sex[0]} value={sex[1]}>{t('patient.sexLabel.' + sex[0])}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="fieldWrapper">
                        <label htmlFor="patientChild-skinType" className={`mandatoryField ${(state.skinType === '') ? 'empty' : 'filled'}`}>{t(['Skin color', 'patient.skinColor'])}</label>
                        <select name="skinType" id="patientChild-skinType" className="hidden" required aria-required="true" disabled>
                            <option value="" />
                            {Object.entries(SkinType).filter((e) => !isNaN(Number(e[1])) && e[1] !== SkinType.NOT_SET).map((skinType) => (
                                <option key={skinType[0]} value={skinType[1]}></option>
                            ))}
                        </select>
                        <div className="patientChild-skinType-selection">
                            {Object.entries(SkinType).filter((e) => !isNaN(Number(e[1])) && e[1] !== SkinType.NOT_SET).map((skinType) => (
                                <PatientSkinTypeTooltip
                                    key={`patient-skin-type-button-${skinType[1]}`}
                                    skinType={skinType[1] as SkinType}
                                    selected={state.skinType === skinType[1]}
                                    onSelected={() => this.handleChangeSkinColor(skinType[1] as SkinType)}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="fieldWrapper">
                        <label htmlFor="patientChild-birthDate-day" className={`mandatoryField ${(!state.isBirthDateValid) ? 'empty' : 'filled'}`}>{t(['Birth date', 'patient.birthDate'])}</label>
                        <div className="fieldWrapper">
                            <DateInput value={state.birthDate} onChange={this.handleChangeBirthDate} minYear={1950} maxYear={new Date().getFullYear()} />
                        </div>
                    </div>
                    {!props.hideCheckboxes &&
                        <div className="fieldWrapper">
                            <div className="checkboxFieldWrapper">
                                <input
                                    type="checkbox"
                                    name="noMonitoringRequired"
                                    id="patientChild-noMonitoringRequired"
                                    required
                                    aria-required="true"
                                    value={1}
                                    checked={this.state.noMonitoringRequired}
                                    disabled={!!(this.props.patient)}
                                    onChange={this.handleChangeNoAlarm}
                                />
                                <label htmlFor="patientChild-noMonitoringRequired" className={`mandatoryCheckbox ${(!this.state.noMonitoringRequired) ? 'empty' : 'filled'}`}>
                                    {t([
                                        'The patient doesn’t require continuous monitoring with physiological alarm system.',
                                        'patient.noMonitoringRequired'
                                    ])}
                                </label>
                            </div>
                        </div>
                    }
                    {state.displayOldChild && (
                        <div className="fieldWrapper">
                            <div className="checkboxFieldWrapper">
                                <input
                                    type="checkbox"
                                    name="oldChild"
                                    id="patientChild-oldChild"
                                    required
                                    aria-required="true"
                                    value={this.state.oldChild.toString()}
                                    checked={this.state.oldChild}
                                    onChange={this.handleChangeOldChild}
                                />
                                <label htmlFor="patientChild-oldChild" className={`mandatoryCheckbox ${(!this.state.oldChild) ? 'empty' : 'filled'}`}>
                                    {t([
                                        'The intended use does not permit use for patients older than 12 years.',
                                        'patient.oldChild.firstText'
                                    ])}
                                    <br />
                                    {t([
                                        'You will use the device under your own responsibility.',
                                        'patient.oldChild.secondText'
                                    ])}
                                </label>
                            </div>
                        </div>
                    )}
                    {state.displayNeoChild && (
                        <div className="fieldWrapper">
                            <div className="checkboxFieldWrapper">
                                <input
                                    type="checkbox"
                                    name="neoChild"
                                    id="patientChild-neoChild"
                                    required
                                    aria-required="true"
                                    value={this.state.neoChild.toString()}
                                    checked={this.state.neoChild}
                                    onChange={this.handleChangeNeoChild}
                                />

                                <label htmlFor="patientChild-neoChild" className={`mandatoryCheckbox ${(!this.state.neoChild) ? 'empty' : 'filled'}`}>
                                    {t([
                                        'The intended use does not permit use for patients younger than 28 days.',
                                        'patient.neoChild.firstText'
                                    ])}
                                    <br />
                                    {t([
                                        'You will use the device under your own responsibility.',
                                        'patient.neoChild.secondText'
                                    ])}
                                </label>
                            </div>
                        </div>
                    )}
                    <div className="step-form-actions">
                        <Button className="button-create" disabled={!this.isFilled()} loading={props.loading}>
                            {(props.patient) ? t(['Save', 'patient.save']) : t(['Next', 'patient.next'])}
                        </Button>
                    </div>
                </form>
            </div>
        );
    }

    handleChangeFirstName(e: ChangeEvent<HTMLInputElement>) {
        this.setState(state => ({
            ...state,
            firstName: e.target.value,
        }), () => {
            this.checkNotSavedDataChild();
        });
    }

    handleChangeLastName(e: ChangeEvent<HTMLInputElement>) {
        this.setState(state => ({
            ...state,
            lastName: e.target.value,
        }), () => {
            this.checkNotSavedDataChild();
        });
    }

    handleChangeBirthDate(e: ChangeEvent<{value: DateInputValue}>) {
        const newDate: DateInputValue = e.target.value as DateInputValue;

        // Entered birthdate is valid
        if (
            newDate.year &&
            `${newDate.year}` !== '' &&
            `${newDate.year}`.length === 4 && !isNaN(newDate.year as number) &&
            `${newDate.month}` !== '' && !isNaN(newDate.month as number) &&
            `${newDate.day}` !== ''
        ) {
            const selectedBirthDate: ApiDate = {
                year: parseInt(`${newDate.year}`),
                month: parseInt(`${newDate.month}`),
                day: parseInt(`${newDate.day}`),
            };

            const today = apiDateToday();

            this.setState(state => ({
                ...state,
                isBirthDateValid: true,
                birthDate: newDate,
                displayOldChild: !selectedBirthDate ? false : isOldChild(selectedBirthDate, today),
                displayNeoChild: !selectedBirthDate ? false : isNeoChild(selectedBirthDate, today),
            }), () => {
                this.checkNotSavedDataChild();
            });
        }
        else {
            this.setState(state => ({
                ...state,
                isBirthDateValid: false,
                birthDate: newDate,
                displayOldChild: false,
                displayNeoChild: false,
            }), () => {
                this.checkNotSavedDataChild();
            });
        }
    }

    handleChangeSex(e: ChangeEvent<HTMLSelectElement>) {
        this.setState(state => ({
            ...state,
            sex: (e.target.value === '') ? '' : parseInt(e.target.value),
        }), () => {
            this.checkNotSavedDataChild();
        });
    }

    handleChangeSkinColor(skinType: number) {
        this.setState(state => ({
            ...state,
            skinType: skinType,
        }), () => {
            this.checkNotSavedDataChild();
        });
    }

    handleChangeNoAlarm() {
        this.setState(state => ({
            ...state,
            noMonitoringRequired: !this.state.noMonitoringRequired,
        }), () => {
            this.checkNotSavedDataChild();
        });
    }

    handleChangeOldChild() {
        this.setState(state => ({
            ...state,
            oldChild: !this.state.oldChild,
        }), () => {
            this.checkNotSavedDataChild();
        });
    }

    handleChangeNeoChild() {
        this.setState(state => ({
            ...state,
            neoChild: !this.state.neoChild,
        }), () => {
            this.checkNotSavedDataChild();
        });
    }

    handleSubmit(e: FormEvent<unknown>) {
        e.preventDefault();
        const state = this.state;

        if (this.props.onSubmit) {
            const patient: PatientChildFormSubmitData = {
                id: this.props.patient?.patientId?.id,
                firstName: state.firstName,
                lastName: state.lastName,
                birthDate: {
                    day: parseInt(state.birthDate.day as unknown as string),
                    month: parseInt(state.birthDate.month as unknown as string),
                    year: parseInt(state.birthDate.year as unknown as string),
                },
                sex: state.sex,
                skinType: state.skinType,
                noMonitoringRequired: state.noMonitoringRequired
            };
            if (this.props.onChanged) {
                this.props.onChanged(false);
            }
            this.props.onSubmit(patient);
        }
    }
}

// @ts-expect-error reassigning the class
//language=SCSS
PatientChildForm = Styled(PatientChildForm)`
& {
    .patientChild-skinType-selection {
        display: flex;

        @media screen and (${medias.mdMax}) {
            flex-wrap: wrap;
            width: 300px;
        }
    }
}
`;

// @ts-expect-error reassigning the class
PatientChildForm = withTranslation()(PatientChildForm);

export {PatientChildForm};
